import React, { useState } from 'react'
import { axiosbaseurl } from '../../../config'
import toast, { Toaster } from 'react-hot-toast'
import { customToastOptions } from '../../../Styles/popup_style'
import { Modal } from 'antd'

const ContactPropreneurForm = ({ property_id, propreneur_id }) => {

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("+91 ")

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState("");
    const [finishModal, setFinishModal] = useState(false);
    const [allow, setAllow] = useState(false);


    // validations for first form
    const handleValidation = () => {
        const newErrors = {};

        if (!name) {
            newErrors.name = "First Name is required!";
        } else if (/\d/.test(name)) {
            newErrors.name = "Digits not allowed in First Name";
        }

        if (!email) {
            newErrors.email = "Email is required";
        } else if (
            !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
        ) {
            newErrors.email = "Invalid email format";
        }

        if (phone.length < 14 || phone.length > 14) {
            newErrors.phone = "Invalid phone number";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    // ===========================


    const handleContactForm = async (e) => {
        e.preventDefault();

        if (handleValidation()) {
            setLoading(true);
            try {
                const formData = new FormData()
                formData.append("fname", name)
                formData.append("email", email)
                formData.append("phone", phone)
                formData.append("property_id", property_id)
                formData.append("propreneur_id", propreneur_id)
                formData.append("is_allow", allow)
                formData.append("type", 'propreneur inquiry')

                const response = await axiosbaseurl.post("/enquiryData", formData, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    },
                });
                // console.log('response : ', response.data)

                if (response.data.success == true) {
                    setFinishModal(true)
                    setName("")
                    setEmail("")
                    setPhone("+91 ")
                    setAllow(false)
                    setFinishModal(true)
                }
            } catch (error) {
                console.log("Error : ", error);
                toast.error("Something went wrong!");
            }
            finally {
                setLoading(false);
            }
        }
    };

    const handleChangePhone = (event) => {
        const value = event.target.value;
        // Check if the value starts with '+91' and remove any extra spaces
        if (value.startsWith('+91 ')) {
            setPhone(value);
        } else {
            // Ensure that '+91 ' is always at the beginning
            setPhone('+91 ' + value.replace(/^(\+91\s*)/, ''));
        }
    };


    return (
        <>
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
            <div className="bd-contact-form">
                <div className="bd-header-sction ">
                    <div className="bd-underscore-overview">
                        <h3 className="bd-contact-header font-saira">Contact Propreneur</h3>
                    </div>
                </div>
                <div>
                    <form onSubmit={handleContactForm} className="pt-20">
                        <div className="pb-15">
                            <label className="bd-label-form ">Full Name</label><br />
                            <input className="bd-contact-input"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                            {
                                errors.name &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.name}</span>
                            }
                        </div>
                        <div className="pb-15">
                            <label className="bd-label-form">Email</label><br />
                            <input className="bd-contact-input"
                                placeholder="XYZ@gmail.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {
                                errors.email &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.email}</span>
                            }
                        </div>
                        <div className="pb-20">
                            <label className="bd-label-form">Mobile</label><br />
                            <input className="bd-contact-input"
                                placeholder="Mobile"
                                value={phone}
                                onChange={handleChangePhone}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key))
                                        event.preventDefault();
                                }}
                                maxLength={14}
                            />
                            {
                                errors.phone &&
                                <span className='badge text-danger '>
                                    <i className='bi bi-exclamation-triangle me-1'></i> {errors?.phone}</span>
                            }
                        </div>
                        <div className='checkbox-wrapper-13 d-flex align-items-center'>
                            <input type="checkbox" id='allow_check' className='custom-check-box'
                                checked={allow} onChange={(e) => setAllow(e.target.checked)} />
                            <label htmlFor='allow_check' className="bd-label-form text-dark-blue2 fw-500 ms-3">
                                Allow the Propreneur to get in touch.</label>
                        </div>

                        <div className="pt-35">
                            <button type='submit' className="bd-submit-btn w-100 py-2" disabled={loading} >
                                {loading ? "Sending Data.." : "Get in touch"}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/* finished modal */}
            <Modal
                open={finishModal}
                title={""}
                width={380}
                maskClosable={false}
                centered
                className='schedule-visit-modal'
                onCancel={() => setFinishModal(false)}
                footer={<div className="footer-none"></div>}
            >
                <div className="p-4">
                    <div className='mt-5'>
                        <div className='text-center my-5'>
                            <i className='bi bi-patch-check-fill display-3' style={{ color: '#39B54A' }}></i>
                            <h3 className='text-dark-blue font-saira mb-0 mt-2 fw-500'>Thank You!</h3>
                            <p className='text-dark-blue font-saira mb-0 py-3'>Your data has been Sent successfully</p>
                        </div>

                        <div className='mb-4 pt-4'>
                            <button onClick={() => setFinishModal(false)} className='blue-btn w-100 font-saira fs-6 fw-600' style={{ borderRadius: '8px' }}>Okay Done</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default ContactPropreneurForm