import React, { useEffect, useState } from 'react'
import SquareDotsImg from "../../assets/footer/square-dots.png"
import Missionimgdots from "../../assets/footer/Our Mission-dots.png"
import "../../Styles/FooterStyle/About.css"
import "../../Styles/FooterStyle/Career.css"
import Slider from 'react-slick'
import { axiosbaseurl } from '../../config'
import CustomLoader from '../CustomLoader'
import "../../App.css"
const AboutUs = () => {

  const [activeCard, setActiveCard] = useState(null)

  const [aboutContent, setAboutContent] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true)
    axiosbaseurl.get(`/aboutPage`)
      .then(res => {
        setAboutContent(res.data)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false)
      })
    return () => {
      setAboutContent({})
      controller.abort();
    };
  }, [])

  const sliderSettings = {
    infinite: false,
    speed: 1000,
    slidesToShow: 3,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    dots: true,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div>
      {loading && <CustomLoader />}

      <div className="pt-90">
        <section className='px-sm-5 px-4 my-5'>
          <div className="row sitemap-section" >
            <div className="col-1 d-md-block d-none position-relative ">
              <div className='bg-yellow h-100'>
                <div className='key-icon bg-dark-blue d-md-block d-none'></div>
              </div>
            </div>

            <section className="col-md-11 pt-4 px-md-4  pb-md-5 pb-4"
              style={{ backgroundColor: '#faf8f4' }}>
              {/* Static Heading */}
              <div className="mb-20 text-md-start text-center">
                {
                  aboutContent &&
                  <h1 className="bd-header-overview mb-0"> <span className='text-yellow'>

                    {aboutContent?.about?.title?.split(' ')[0]} </span>
                    {aboutContent?.about?.title?.split(' ')?.slice(1)?.join(' ')}</h1>
                }
                <div className="yellow-line mx-md-0 mx-auto"></div>
              </div>
              {/* ======================== */}

              <div>
                <div className='about-content'>
                  <div>
                    <p className="about-first-para fw-bold">
                      {aboutContent?.about?.subtitle}
                    </p>

                    <div
                      dangerouslySetInnerHTML={{ __html: aboutContent?.about?.description }}
                      className="about_desc"
                    />

                  </div>
                </div>
              </div>

            </section>

          </div>
        </section>

        <section className="container-3 py-5 responsive-spacing my-md-5">
          <div className='row align-items-center position-relative py-md-4 mt-md-5'>
            <div className="col-md-6 about-secnd-1">
              <div className='row align-items-center mission-galary pe-md-5'>
                <div className='col-5 px-2 text-end'>
                  <img src={aboutContent?.our_mission?.images[0]} alt="" className='mission-image bg-light mission-image1' />
                  <img src={aboutContent?.our_mission?.images[1]} alt="" className='mission-image bg-light mission-image2' />
                </div>
                <div className='col-7 px-2 '>
                  <img src={aboutContent?.our_mission?.images[2]} alt="" className='mission-image bg-light mission-image3' />
                  <img src={aboutContent?.our_mission?.images[3]} alt="" className='mission-image bg-light mission-image4' />
                </div>
                <img src={SquareDotsImg} alt="" className='left-square-dots' />
                <img src={SquareDotsImg} alt="" className='right-square-dots' />
              </div>

            </div>
            <div className="col-md-6 bd-our-mission mt-md-0 mt-5">
              <div className='mt-md-0 mt-5 px-md-0 px-4'>
                <div className='mt-md-0 mt-5 pb-2'>
                  <h3 className="bd-header-overview mb-0"><span className='color-yellow'>
                    {aboutContent?.our_mission?.title?.split(' ')[0]} </span>
                    {aboutContent?.our_mission?.title?.split(' ').slice(1).join(' ')}</h3>
                  <div className="yellow-line"></div>
                </div>
                <div >
                  <p className="about-first-para fw-500 my-3">
                    {aboutContent?.our_mission?.subtitle}
                  </p>
                  <p className='paragraph pe-md-5 me-md-5'
                    dangerouslySetInnerHTML={{ __html: aboutContent?.our_mission?.description }}
                  />
                </div>
              </div>
            </div>
            <img src={Missionimgdots} alt="" className='mission-rectangle-dots d-md-block d-none' />
          </div>

        </section>

        {/* vision */}
        <section className='pb-5 vision-top-shadow border-top border-2'>
          <div className='pt-md-5'>
            <div className="row mx-0 justify-content-end position-relative">
              {/* <div className="col-md-4"> </div> */}

              <div className="col-md-8 px-0 vision-box py-5 " style={{
                backgroundImage: `url(${aboutContent?.our_vision?.images[0]})`
              }}>
                <div className='vision-details'>
                  <div className='mb-4'>
                    <h3 className="bd-header-overview mb-0"><span className='color-yellow'>
                      {aboutContent?.our_vision?.title?.split(' ')[0]} </span>
                      {aboutContent?.our_vision?.title?.split(' ').slice(1).join(' ')}</h3>
                    <div className="yellow-line"></div>
                  </div>
                  <div>
                    <p className="about-first-para fw-500">
                      {aboutContent?.our_vision?.subtitle}
                    </p>
                    <p className='paragraph'
                      dangerouslySetInnerHTML={{ __html: aboutContent?.our_vision?.description }}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>

        {/* Top Propenuer */}
        <section className='py-5' style={{ backgroundColor: '#faf4e4' }}>
          <div className="container-box">
            <div className='d-flex justify-content-center'>
              <div className='mb-5'>
                {
                  aboutContent?.titles &&
                  <h3 className="bd-header-overview mb-0"><span className='color-yellow'>
                    {aboutContent?.titles?.find(item => item.section == 'Top Propreneur')?.title?.split(' ')[0]} </span>
                    {aboutContent?.titles?.find(item => item.section == 'Top Propreneur')?.title?.split(' ').slice(1).join(' ')}</h3>
                }
                <div className="yellow-line"></div>
                <div>
                  <p className="text-dark mb-0 mt-3 font-saira text-center">
                    {aboutContent?.titles?.find(item => item.section == 'Top Propreneur')?.subtitle}
                  </p>
                </div>
              </div>
            </div>

            <div className='py-5 px-md-5 position-relative overflow-hidden' style={{ borderRadius: '20px' }}>
              <div className="row">
                <Slider {...sliderSettings}  >
                  {
                    aboutContent?.top_proprenuer?.proprenuers?.filter(item => item.status == 1)?.map((prop, i) => {
                      return (
                        <div className="col-xxl-3 col-md-4 mb-md-4 mb-5 px-2" key={i}>
                          <div className="top_proprenuer_card p-3 bg-white" onClick={() => setActiveCard(prop)}>
                            <img src={prop.image_url} alt="" className="top_card_img" />
                            <div className='p-2 pt-4'>
                              <h5 className='text-dark-blue2'>{prop.name}</h5>
                              <div className='text-yellow'>{prop.current_occupancy}</div>
                            </div>
                          </div>
                        </div>
                      )
                    })
                  }
                </Slider>
              </div>

              {
                (activeCard?.name) &&
                <div className='active_card_box' onClick={() => setActiveCard(null)}>
                  <div className="row justify-content-end h-100">
                    <div className="col-xl-4 col-lg-5 col-md-6 h-100">
                      <div className="top_proprenuer_card active_card h-100 bg-white" onClick={(e) => e.stopPropagation()}>
                        <img src={activeCard?.image_url} alt="" />

                        <span className='bi bi-x close-card-btn' onClick={() => setActiveCard(null)}></span>

                        <div className='p-3'>
                          <h5 className='text-dark-blue'>{activeCard?.name}</h5>
                          <div className='text-yellow mb-2'>{activeCard?.current_occupancy}</div>

                          <div className='prop_desc mb-1'
                            style={{ height: '155px', overflow: 'auto', scrollbarWidth: 'thin' }}
                            dangerouslySetInnerHTML={{ __html: activeCard?.description }}
                          />
                          {/* {activeCard?.description?.slice(0, 280)}..
                          </div> */}
                        </div>

                        <div className='d-flex justify-content-between align-items-end px-3'>
                          <div>
                            <div>
                              <i className='fa fa-envelope text-yellow'></i>
                              <a href={`mailto:${activeCard?.email}`} className='ms-2 prop_desc'>{activeCard?.email}</a>
                            </div>
                            <div>
                              <i className='fa fa-phone text-yellow'></i>
                              <a href={`tel:${activeCard?.contact}`} className='ms-2 prop_desc'>{activeCard?.contact}</a>
                            </div>
                          </div>

                          <div>
                            <a href={activeCard?.twitter} target='_blank' rel="noreferrer" className='text-decoration-none mx-md-2 mx-1'> <i className="fa fa-twitter color-twitter"></i> </a>
                            <a href={activeCard?.facebook} target='_blank' rel="noreferrer" className='text-decoration-none mx-md-2 mx-1'> <i className="fa fa-facebook color-facebook"></i> </a>
                            <a href={activeCard?.linkedn} target='_blank' rel="noreferrer" className='text-decoration-none mx-md-2 mx-1'> <i className="fa fa-linkedin color-linkedin"></i> </a>
                            <a href={activeCard?.instagram} target='_blank' rel="noreferrer" className='text-decoration-none mx-md-2 mx-1'> <i className="fa fa-instagram color-instagram"></i> </a>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              }
            </div>
          </div>
        </section>
      </div>

    </div>
  )
}

export default AboutUs