import React, { useEffect, useState } from "react";
import { axiosbaseurl } from "../../config";
import CustomLoader from "../CustomLoader";
import "../../assets/Css/Rera.css";
import Pagination from "@mui/material/Pagination";
import Slider from "react-slick";

const Rera = () => {
  const [tableData, setTableData] = useState([]);
  const [reraData, setReraData] = useState([]);
  const [headerData, setHeaderData] = useState({});

  const [loading, setLoading] = useState(false);

  const [activeCard, setActiveCard] = useState(null);
  const [searchState, setSearchState] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get("/reraPage")
      .then((res) => {
        setHeaderData(res.data.intro)
        setTableData(res.data.states);
        setReraData(res.data.states);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));

    return () => {
      setTableData([])
      setReraData([])
      setHeaderData({})
      controller.abort();
    };
  }, []);

  const itemsPerPage = 8; // Number of items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page state

  // Calculate index of the first and last item to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = reraData.slice(indexOfFirstItem, indexOfLastItem);

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const sliderSettings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    swipeToSlide: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 985,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          arrows: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  const onSearchState = () => {
    setReraData(tableData?.filter(item => item.state_name?.toLowerCase().includes(searchState?.trim()?.toLowerCase())))
    window.scrollTo(0, 350)
  }
  const onRefresh = () => {
    setSearchState('')
    setReraData(tableData)
  }
  return (
    <div>

      {loading && <CustomLoader />}

      <div className="pt-90">
        <div className="bd-banner-rera"
          style={{
            backgroundImage: `url(${headerData?.bg_img})`,
          }}
        >
          <div className="rera-banner-overlay"></div>
          <div className="bd-rerabanner-section">
            <div className="rerabanner-inner-section">
              <div>
                <p className="rera-main-head-banner">
                  <span> {headerData?.title?.split(' ')[0]}</span>
                  <strong> {headerData?.title?.split(' ')?.slice(1)?.join(' ')}</strong>
                </p>
              </div>
              <div className="main-head-bannertext">
                <p 
                  dangerouslySetInnerHTML={{ __html: headerData?.subtitle }}
                />
              </div>

              <div className="search-div">
                <form onSubmit={(e) => {
                  e.preventDefault()
                  onSearchState()
                }}>
                  <div className="d-md-flex align-items-end">
                    <div className="text-start">
                      <p className="state-p mb-1">SEARCH STATE</p>
                      <input type="text" className="bd-rera-search border-0 bg-white ps-3"
                        value={searchState}
                        onChange={(event) => setSearchState(event.target.value)}
                        style={{ borderRadius: "25px", outline: 'none' }}
                        placeholder="Name Of State"
                      />
                    </div>
                    <div className="d-flex align-items-center mt-md-0 mt-4">

                      <div className="search-button d-flex gap-3 align-items-center " onClick={onSearchState}>
                        <i className="bi bi-search"></i>
                        <p>Search</p>
                      </div>
                      <div className="refresh-button d-inline-block" onClick={onRefresh}>
                        <i
                          className="bi bi-arrow-repeat"
                          style={{ fontSize: "22px" }}
                        ></i>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="rera-table-section py-5 ">
          <div className="px-md-5 ">
            <div className="table-responsive">
              <table className="rera-table col-md-5">
                <tr className="rera-table-header">
                  <th>STATE</th>
                  <th>PROJECT APPLICATIONS RECEIVED</th>
                  <th>REGISTERED PROJECTS</th>
                  <th>AGENT APPLICATIONS RECEIVED</th>
                  <th>REGISTERED AGENTS</th>
                  <th>COMPLAINTS DISPOSED</th>
                  <th>DATA AS ON</th>
                  <th>MORE DETAILS</th>
                </tr>

                <tbody className="rera-table-body">
                  {currentItems?.map((row, index) => (
                    <tr key={index}>
                      <td>{row.state_name}</td>
                      <td>{row.project_application_recieved}</td>
                      <td>{row.registered_projects}</td>
                      <td>{row.agent_applications_received}</td>
                      <td>{row.registered_agents}</td>
                      <td>{row.complaints_disposed}</td>
                      <td>{row.data_as_on}</td>
                      <td>
                        <button className="table-button text-capitalize">{'View'}</button>
                      </td>
                    </tr>
                  ))}

                  {
                    reraData?.length === 0 &&
                    <tr>
                      <td colSpan={8} className="text-center">No Record Found !</td>
                    </tr>
                  }
                </tbody>
              </table>
            </div>
            <div className="pagination-text">
              {" "}
              Showing {indexOfFirstItem + 1} to{" "}
              {Math.min(indexOfLastItem, reraData.length)} of {reraData.length}
            </div>
            <div className="d-flex justify-content-center mt-5">
              <Pagination
                count={Math.ceil(reraData.length / itemsPerPage)}
                page={currentPage}
                onChange={handlePageChange}
                sx={{
                  "& .MuiPaginationItem-root": {
                    borderRadius: "35%",
                    backgroundColor: "#d9d9d9",
                    color: "#132845",
                    minWidth: "40px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "0px 8px",
                  },
                  "& .MuiPaginationItem-page.Mui-selected": {
                    backgroundColor: "#f6ca51",
                    color: "#132845",
                  },
                  "& .MuiPagination-ul": {
                    justifyContent: "center", // Center align pagination items
                  },
                }}
              />
            </div>
          </div>

          <div className="row mt-5">
            <Slider {...sliderSettings} className="pb-4">
              {tableData?.map((prop, i) => {
                return (
                  <div className="col-xxl-3 col-md-4 mb-md-0 mb-5 px-2" key={i}>
                    <div
                      className="  bg-white"
                    // onClick={() => setActiveCard(prop)}
                    >
                      <div className="p-2 pt-4 rera-slider-div">
                        <h5 className="text-dark-blue text-uppercase">{prop?.state_name}</h5>
                        <div className="text-dark-blue">
                          <p className="text-capitalize">{'Choose Your preferred tehsil'}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Rera;
