import React, { useContext, useRef, useState } from 'react'
import { customToastOptions } from '../../Styles/popup_style'
import toast, { Toaster } from 'react-hot-toast'
import { axiosbaseurl } from '../../config'
import CustomLoader from '../CustomLoader'
import { AppGlobalContext } from '../../GlobalContext'

const CareerForm = ({ postLists, formheading }) => {

    const [formData, setFormData] = useState({
        fname: '',
        email: '',
        phone: '+91 ',
        position: '',
        file: null,
        type: 'career'
    })
    const [errors, setErrors] = useState({})
    const [loading, setLoading] = useState('')

    const fileInputRef = useRef(null);

    const { current_location } = useContext(AppGlobalContext);

    // const [resumeFile, setResumeFile] = useState('')

    // validations for first form
    const handleValidation = () => {

        const newErrors = {};

        if (!formData?.fname) {
            newErrors.name = 'Name is required';
        } else if ((/\d/).test(formData?.name)) {
            newErrors.name = 'Digits not allowed in First Name';
        }


        if (!formData?.email) {
            newErrors.email = 'Email is required';
        } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formData?.email)) {
            newErrors.email = 'Invalid email format';
        }

        if (!formData?.phone) {
            newErrors.phone = 'Phone No. is required';
        } else if (formData?.phone.length < 7 || formData?.phone.length > 15) {
            newErrors.phone = 'Invalid phone number';
        }

        if (!formData?.position) {
            newErrors.position = 'Position is required';
        }

        if (!formData?.file) {
            newErrors.file = 'Resume is required';
        }

        setErrors(newErrors)
        return (Object.keys(newErrors).length === 0);
    }
    // ===========================

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (handleValidation()) {
            setLoading(true)
            try {
                const form_data = { ...formData, location: current_location }
                const response = await axiosbaseurl.post('/careerenquiryData', form_data, {
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "multipart/form-data",
                    }
                })

                setLoading(false)

                if (response.data.success == true) {

                    toast.success("Your details has been sent successfully !!");

                    const toastDuration = 1000;

                    setTimeout(() => {
                        setFormData({
                            fname: '',
                            email: '',
                            phone: '',
                            position: '',
                            file: null,
                            type: 'career'
                        });
                        if (fileInputRef.current) {
                            fileInputRef.current.value = ''; // Resets the file input
                        }
                        setErrors({});
                        window.scrollTo({ top: 0, behavior: 'instant' });
                    }, toastDuration);

                }


            } catch (error) {
                console.log("Error : ", error)
                toast.error("Something went wrong!");
                setLoading(false)

            }
        }
    }

    function collectFormData(e) {
        let tempData;
        if (e.target.files) {
            tempData = e.target.files[0]
            if (tempData?.type !== 'application/pdf' &&
                tempData?.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
                setErrors({ ...errors, file: 'Only Pdf & Docx file accepted' })
                setFormData({ ...formData, file: null })
                return
            } else {
                setErrors({})
            }
        }
        else {
            if (e.target.name === "phone") {
                if (e.target.value.startsWith('+91 ')) {
                    tempData = e.target.value
                } else {
                    // Ensure that '+91 ' is always at the beginning
                    tempData = '+91 ' + e.target.value.replace(/^(\+91\s*)/, '');
                }
            } else {
                tempData = e.target.value
            }
        }

        setFormData(prev => ({ ...prev, [e.target.name]: tempData }))
    }

    return (
        <>
            {loading && <CustomLoader />}
            <Toaster position="top-right" reverseOrder={false} toastOptions={customToastOptions} />
            <section className='py-5'>
                <div className="container-box py-md-4">
                    <div className='career-text-content  text-center my-5'>
                        <h2><span className='text-yellow fw-normal'>{formheading?.title?.split(' ')[0]} </span>
                            {formheading?.title?.split(' ')?.slice(1)?.join(' ')}</h2>
                        <p style={{ fontWeight: 500, fontSize: '16px' }}>
                            {formheading?.subtitle}
                        </p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="row px-md-4">
                            <div className="col-md-6 mb-4 pb-2 px-md-4">
                                <div>
                                    <label style={{ fontSize: '15px' }} className='text-dark-blue mb-3'> Name</label>
                                    <input type="text" placeholder='Name'
                                        onChange={collectFormData}
                                        value={formData?.fname}
                                        name="fname"
                                        className='form-field career-form-field' />
                                    <span className='text-danger badge'>{errors?.name}</span>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4 pb-2 px-md-4">
                                <div>
                                    <label style={{ fontSize: '15px' }} className='text-dark-blue mb-3'> Email ID</label>
                                    <input type="text" placeholder='Email ID'
                                        onChange={collectFormData}
                                        value={formData?.email}
                                        name="email"
                                        className='form-field career-form-field' />
                                    <span className='text-danger badge'>{errors?.email}</span>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4 pb-2 px-md-4">
                                <div>
                                    <label style={{ fontSize: '15px' }} className='text-dark-blue mb-3'> Phone No.</label>
                                    <input type="text" placeholder='+91-'
                                        onChange={collectFormData}
                                        value={formData?.phone}
                                        name="phone"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key))
                                                event.preventDefault();
                                        }}
                                        maxLength={14}
                                        className='form-field career-form-field' />
                                    <span className='text-danger badge'>{errors?.phone}</span>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4 pb-2 px-md-4">
                                <div>
                                    <label style={{ fontSize: '15px' }} className='text-dark-blue mb-3'> Position Applied For</label>
                                    <select
                                        onChange={collectFormData}
                                        value={formData?.position}
                                        name="position"
                                        className='form-select career-form-field border-0'>
                                        <option value="">-- Select -- </option>
                                        {
                                            postLists?.map(job => {
                                                return (
                                                    <option key={job.id} value={job.title}> {job.title} </option>
                                                )
                                            })
                                        }
                                    </select>
                                    <span className='text-danger badge'>{errors?.position}</span>
                                </div>
                            </div>

                            <div className="col-md-6 mb-4 pb-2 px-md-4">
                                <div>
                                    <label style={{ fontSize: '15px' }} className='text-dark-blue mb-3'> Upload Resume
                                        <span style={{ color: '#ff0000' }}> (Only Pdf & Docx file)</span></label>
                                    <input type="file" onChange={collectFormData}
                                        name="file" className='form-field career-form-field file-yellow-btn'
                                        ref={fileInputRef}
                                    />
                                    <span className='text-danger badge'>{errors?.file}</span>
                                </div>
                            </div>

                            <div className="col-12  text-end">
                                <button type='submit' className='btn py-2 fw-600 bg-yellow text-dark-blue' style={{ fontSize: '14px', width: '180px' }}>
                                    Submit
                                </button>
                            </div>

                        </div>
                    </form>
                </div>
            </section>
        </>
    )
}

export default CareerForm