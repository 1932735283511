import "./App.css";
import "../src/Styles/Main.css";
// import 'bootstrap/dist/css/bootstrap.css';
import "./Styles/Bootstrap.css";

import SignUPMain from "./Components/Login Pages/SignUPMain";
import ForgotPage from "./Components/Forgot Password/ForgotPage";
import Verifypage from "./Components/Forgot Password/Verifypage";
import PassChange from "./Components/Forgot Password/PassChange";
import CreateAccount from "./Components/CreateAccount/CreateAccount";
import SignUpOtp from "./Components/Forgot Password/SignUpOtp";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "./Components/LandingPage/LandingPage";
import Propreneur from "./Components/PropreneurPage/Propreneur";
import AgentDetails from "./Components/PropreneurPage/AgentDetails";
import BuyVisitingTime from "./Components/Properties/BuyVisitingTime";
import FindProprenuerDetail from "./Components/PropreneurPage/FindProprenuerDetail";
import AboutUs from "./Components/Footer pages/About-us";
import ContactUs from "./Components/Footer pages/Contact-us";
import ScrollToTop from "./Components/ScrollToTop";
import ManagementTeam from "./Components/Footer pages/ManagementTeam";
import Career from "./Components/Footer pages/Career";
import OfficeLocation from "./Components/Footer pages/OfficeLocation";
import config, { axiosbaseurl } from "./config";
import AgentForm01 from "./Components/AgentForm/AgentForm01";
import SignInFirst from "./Components/Login Pages/SignInFirst";
import LoginOtp from "./Components/Login Pages/LoginOtp";
import CreateAccountFirst from "./Components/CreateAccount/CreateAccountFirst";
import CreateAccountOtp from "./Components/CreateAccount/CreateAccountOtp";
import PropUserLocality from "./Components/PagesUnderHome/PropUserLocality";
import Rera from "./Components/Footer pages/Rera";
import Authorities from "./Components/Footer pages/Authorities";
import AllJobPosts from "./Components/Footer pages/AllJobPosts";
import JobDetails from "./Components/Footer pages/JobDetails";
import Blog from "./Components/Footer pages/Blog";
import Neighbourhoodcities from "./Components/PagesUnderHome/Neighbourhoodcities";
import News from "./Components/Footer pages/News";
import AllProprenuers from "./Components/Footer pages/AllProprenuers";
import ComingSoon from "./Components/ComingSoon";
import UserProfile from "./Components/UserProfile/UserProfile";
import Sitemap from "./Components/Footer pages/Sitemap";
import AllCities from "./Components/PagesUnderHome/AllCities";
import MakeoverDetail from "./Components/PagesUnderHome/MakeoverDetail";
import IndianMap from "./Components/Footer pages/IndianMap";
import LocalityDetailNew from "./Components/PagesUnderHome/LocalityDetailNew";
import HomeLoanIndex from "./Components/HomeLoan/HomeLoanIndex";
import PropreneurExperience from "./Components/Footer pages/Propreneur Experience/PropreneurExperience";
import TermsConditions from "./Components/Footer pages/Company Details/TermsConditions";
import PrivacyPolicies from "./Components/Footer pages/Company Details/PrivacyPolicies";
import JoinPropreneurNew from "./Components/PropreneurPage/JoinPropreneurNew";

import NavBar from "./Components/shared/NavBar";
import Footer from "./Components/shared/FooterComponents/Footer";
import AllNeighbourCities from "./Components/PagesUnderHome/AllNeighbourCities";
import EmailSettings from "./Components/UserProfile/EmailSettings";
// properties
import BuyCosting from "./Components/Properties/BuyCosting/BuyCosting";
import PropertyShowCase from "./Components/Properties/PropertyShowCase/PropertyShowCase";
import PropertyListing from "./Components/Properties/PropertyListing";
import BuyDetailPage from "./Components/Properties/BuyPage/BuyDetailPage";

import NearbyCities from "./NearByCities";
import RentDetailpage from "./Components/Properties/RentPage/RentDetailpage";

const App = () => {
  const [homePageData, setHomePageData] = useState(null)
  const [loading, setLoading] = useState(false);

  const [isTransparentNav, setIsTransparentNav] = useState(false)
  const [isSearchBarNav, setIsSearchBarNav] = useState(true)

  const location = useLocation()

  useEffect(() => {
    const controller = new AbortController();
    setLoading(true);
    axiosbaseurl
      .get(`/homeData`)
      .then((response) => {
        setHomePageData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setHomePageData(null)
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setIsTransparentNav(checkNavbarTransparentWithNoSearchBar())
    setIsSearchBarNav(!checkNavbarTransparentWithNoSearchBar())
  }, [location.pathname])

  const checkNavbarTransparentWithNoSearchBar = () => {
    const links = [config.appUrl, `${config.appUrl}coming-soon`]
    return links.some(item => item == location.pathname)
  }

  return (
    <>
      <ScrollToTop />
      <NavBar isSearchBar={isSearchBarNav} isTransparent={isTransparentNav} homePageData={homePageData} />

      <Routes>
        <Route path="*" element={<LandingPage homePageData={homePageData} loading={loading} />}></Route>
        <Route path={`${config.appUrl}`} element={<LandingPage homePageData={homePageData} loading={loading} />}></Route>

        <Route
          exact
          path={`${config.appUrl}login`}
          element={<SignInFirst />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}login-otp`}
          element={<LoginOtp />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}sign-in`}
          element={<SignUPMain />}
        ></Route>

        <Route
          exact
          path={`${config.appUrl}forgot-password`}
          element={<ForgotPage />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}sign-in-otp`}
          element={<SignUpOtp />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}Verifypage`}
          element={<Verifypage />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}PassChange`}
          element={<PassChange />}
        ></Route>

        <Route
          exact
          path={`${config.appUrl}register`}
          element={<CreateAccount />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}create-account`}
          element={<CreateAccountFirst />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}create-account-otp`}
          element={<CreateAccountOtp />}
        ></Route>
        {/* Profile */}
        <Route
          exact
          path={`${config.appUrl}user-profile`}
          element={<UserProfile />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}email-settings`}
          element={<EmailSettings />}
        ></Route>

        {/* footer pages */}
        <Route
          exact
          path={`${config.appUrl}about-us`}
          element={<AboutUs />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}management-team`}
          element={<ManagementTeam />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}office-location`}
          element={<OfficeLocation />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}indian-map`}
          element={<IndianMap />}
        ></Route>

        <Route
          exact
          path={`${config.appUrl}career`}
          element={<Career />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}job-posts`}
          element={<AllJobPosts />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}job-posts/:category_id`}
          element={<AllJobPosts />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}job-details/:post_slug`}
          element={<JobDetails />}
        ></Route>

        <Route
          exact
          path={`${config.appUrl}contact-us`}
          element={<ContactUs />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}terms-and-condition`}
          element={<TermsConditions />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}privacy-policy`}
          element={<PrivacyPolicies />}
        ></Route>
        <Route exact path={`${config.appUrl}rera`} element={<Rera />}></Route>
        <Route
          exact
          path={`${config.appUrl}authorities`}
          element={<Authorities />}
        ></Route>

        <Route exact path={`${config.appUrl}blog`} element={<Blog />}></Route>
        <Route
          exact
          path={`${config.appUrl}blog/:blog_slug`}
          element={<Blog />}
        ></Route>
        <Route exact path={`${config.appUrl}news`} element={<News />}></Route>
        <Route
          exact
          path={`${config.appUrl}news/:news_slug`}
          element={<News />}
        ></Route>

        <Route
          exact
          path={`${config.appUrl}neighbourhoods`}
          element={<Neighbourhoodcities homePageData={homePageData} loading={loading} />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}our-cities`}
          element={<AllCities homePageData={homePageData} loading={loading} />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}our-neighbourcities`}
          element={<AllNeighbourCities homePageData={homePageData} loading={loading} />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}proprenuers`}
          element={<AllProprenuers />}
        />
        <Route
          exact
          path={`${config.appUrl}sitemap`}
          element={<Sitemap />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}propreneur-experience`}
          element={<PropreneurExperience />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}coming-soon`}
          element={<ComingSoon />}
        ></Route>

        {/* ==================================== */}

        {/* new */}
        <Route
          exact
          path={`${config.appUrl}agent-registration`}
          element={<AgentForm01 />}
        ></Route>

        {/* Pages under home */}
        <Route
          exact
          path={`${config.appUrl}propuser-makeover`}
          element={<MakeoverDetail />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}propuser-locality/:city_id`}
          element={<PropUserLocality />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}propuser-locality-detail/:locality_id`}
          element={<LocalityDetailNew homePageData={homePageData} />}
        ></Route>

        <Route
          exact
          path={`${config.appUrl}find-a-propreneur`}
          element={<Propreneur />}
        />
        <Route
          exact
          path={`${config.appUrl}propusers-find-a-propreneur`}
          element={<FindProprenuerDetail />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}join-as-a-propreneur`}
          // element={<JoinPropreneur />}
          element={<JoinPropreneurNew />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}propusers-agent-detail/:prop_id`}
          element={<AgentDetails />}
        ></Route>
        <Route
          exact
          path={`${config.appUrl}home-loan`}
          element={<HomeLoanIndex />}
        ></Route>

        <Route exact path={`${config.appUrl}near-by-city`} element={<NearbyCities />}></Route>

        {/* property routes */}
        <Route
          exact
          path={`${config.appUrl}property/:property_type`}
          element={<PropertyListing />}
        ></Route>
        <Route exaxt path={`${config.appUrl}home-for-sale/property-details/:property_id`} element={<BuyDetailPage />}></Route>
        <Route exaxt path={`${config.appUrl}home-for-rent/property-details/:property_id`} element={<RentDetailpage />}></Route>
        <Route exaxt path={`${config.appUrl}property-showcase/:property_type/:property_id/:tab_name`} element={<PropertyShowCase />}></Route>

        <Route exaxt path={`${config.appUrl}property-buy-costing-details`} element={<BuyCosting />}></Route>

        <Route exact path={`${config.appUrl}property/schedule-visit/:property_id`} element={<BuyVisitingTime />}></Route>

        <Route
          exact
          path={`${config.appUrl}property/neighbourhoods/:city_id`}
          element={<PropUserLocality />}
        ></Route>

        {/* <Route exact path="/propuser/propusers_rent-filter-listview" element={<ListingViewRent />}></Route> */}
        {/* <Route exact path="/propuser/propuser-buy-filter" element={<BuyFilter />}></Route> */}

        {/* <Route exact path="/propuser/sell" element={<MainSell />}></Route> */}

      </Routes>
      <Footer />
    </>
  );
};

export default App;
