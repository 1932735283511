import React, { useContext, useEffect, useState } from 'react'
import "../../Styles/PropreneurStyles/Propreneur.css"
import { Link } from "react-router-dom"
import config from '../../config'
import { AppGlobalContext } from '../../GlobalContext'


const Propreneur = () => {

  const [searchStr, setSearchStr] = useState('')

  const [allCities, setAllCities] = useState([])
  const { cities_options, join_heading } = useContext(AppGlobalContext)


  useEffect(() => {

    if (cities_options) {
      const sortedNames = [...cities_options].sort((a, b) => a['city_name']?.localeCompare(b['city_name']));
      setAllCities(sortedNames)
    }

    return () => { }

  }, [cities_options])

  return (
    <div>

      <section className="container1 mt-90" >
        {/* <Link to="/propusers-find-a-propreneur"> */}
        <div className="overlay-container">
          <section className="pt-90  top-cities-banner "
            style={{
              backgroundImage: `linear-gradient(#00000050, #00000050), url("${join_heading?.bg_image}")`
            }}
          >
            <div className="d-flex justify-content-center align-items-center text-center h-100">
              <div>
                {
                  join_heading &&
                  <div className='mb-4 propreneur-header-text'>
                    <h2 className="bd-heading-detail"
                      dangerouslySetInnerHTML={{ __html: join_heading?.heading?.title }}
                    >
                    </h2>
                    <p className="bd-heading-detail"
                      dangerouslySetInnerHTML={{ __html: join_heading?.heading?.subtitle }}
                    >
                    </p>
                  </div>
                }

                <div >
                  <div className="main-searh-input-proprenuer">
                    <div className="bd-search-bar">
                      <input className="bd-search-input-bar "
                        value={searchStr}
                        onChange={(e) => setSearchStr(e.target.value)}
                        placeholder="Find a Propreneur" />
                    </div>
                    <div className="bd-button-item">
                      <Link to={`${config.appUrl}propusers-find-a-propreneur`}
                        state={{
                          searchStr
                        }}
                      >
                        <button className="bd-search-button-bar"><i className="fa fa-search search-icons"></i></button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* </Link> */}
      </section>

      <section className="py-4 px-md-0 px-2">
        <div className="container-fluid py-4 px-4" >


          <div className="d-md-flex justify-content-between">
            <div className="city-header mb-4" style={{ flex: 1 }}>
              <p className="mb-0">Our Cities:</p>
              <div className="yellow-line"></div>
            </div>

            <div className='d-flex flex-wrap align-items-start top-cities'>
              {
                allCities?.map((city) => {
                  return (
                    <div key={city.id} >
                      <Link
                        to={`${config.appUrl}propusers-find-a-propreneur`}
                        state={{
                          searchStr: city.city_name
                        }}
                        className='text-decoration-none font-saira text-444'>
                        {city.city_name}
                      </Link>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </section>




    </div>
  )
}

export default Propreneur
